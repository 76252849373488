<template>
<div >
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="2" align="center">      
            <span  class="blink" style="font-size:16px;font-weight:bold;color:red;">You have not yet Freezed the Personal Details,Kindly freeze it.
            (If you have already done it before , Please do it once again)</span>
        </div>
        <div v-else>
            <span style="font-size:16px;font-weight:bold;color:green;"> Personal Details Freezed.</span>
        </div>
        
        
        <v-toolbar flat dense class="tc-title">
             <router-link to="student-profile" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Personal Details</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
             <router-link to="student-addressDetals" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
             <a class="blink" href="https://www.youtube.com/watch?v=VVbTYMH_3zA" target="_blank"><span style="color:red;font-size:35px;" class="mdi mdi-message-video"></span></a>
        </v-toolbar>
        <v-card v-if="student_Data.ispersonaldetailsfreezed == false" >
           <v-card-text>
               <v-row >
                <v-col cols="12" sm="3">  
                    <label>Registration Number<span class="required">*</span> :</label>
                 <v-row no-gutters> <span class="lbl"> {{student_Data.grno}}</span>  </v-row >                  
                    <!-- <label>Registration Number</label><h4>{{student_Data.grno}}</h4> -->
                    <!-- <v-text-field placeholder="Registration Number" readonly="true" label="Registration Number"  v-model="student_Data.grno"></v-text-field> -->
                 </v-col>
                <v-col cols="12" sm="3" v-if="student_Data.fname == null">
                    <label> First Name<span class="required">*</span> :</label>
                   <!-- <v-row no-gutters> <span class="lbl"> {{student_Data.fname}}</span>  </v-row >    -->
                    <v-text-field :rules="nameRules" :error-messages="error.fname ? 'Please Enter First name' : ''" placeholder="Enter First Name" hint="Enter text only" v-model="student_Data.fname" 
                     @change="fullname()"  oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" v-else>
                     <label> First Name<span class="required">*</span> :</label>
                   <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.fname}}</span>  </v-row >   -->
                    <v-text-field :rules="nameRules"  placeholder="Enter First Name"  oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"  v-model="student_Data.fname"  @change="fullname()"></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="3" v-if="student_Data.mname == null">
                    <label>Middle Name<span class="required">*</span> :</label>-->
                    <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.mname}}</span>  </v-row >  -->
                    <!-- <v-text-field placeholder="Enter Middle Name"   v-model="student_Data.mname"></v-text-field> -->
                <!-- </v-col> -->
                 <!-- <v-col cols="12" sm="3" v-else> -->
                     <!-- <label>Middle Name<span class="required">*</span> :</label> -->
                    <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.mname}}</span>  </v-row >  -->
                    <!-- <v-text-field readonly placeholder="Enter Middle Name"  label="Middle Name"  v-model="student_Data.mname"></v-text-field> -->
                <!-- </v-col> --> 
                <v-col cols="12" sm="3">
                 <label>Middle Name<span class="required"></span> :</label>
                   <v-text-field :rules="nameRules" placeholder="Enter Middle Name" v-model="student_Data.mname" 
                     @change="fullname()" oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"></v-text-field>
                 </v-col>  
                <v-col cols="12" sm="3" v-if="student_Data.lname == null">
                    <label>Last Name<span class="required">*</span> :</label>
                    <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.lname}}</span>  </v-row > 
                   <v-text-field placeholder="Enter Last Name"  label="Last Name" @change="fullname()" v-model="student_Data.lname"></v-text-field> -->
                   <v-text-field :rules="nameRules" placeholder="Enter Last Name" oninput="this.value = this.value.replace(/[^A-Za-z]/, '')" v-model="student_Data.lname" 
                    @change="fullname()" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" v-else>
                  <label>Last Name<span class="required">*</span> :</label>
                    <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.lname}}</span>  </v-row >  -->
                   <v-text-field :rules="nameRules" placeholder="Enter Last Name" oninput="this.value = this.value.replace(/[^A-Za-z]/, '')" v-model="student_Data.lname"  @change="fullname()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" v-if="student_Data.fullname == null">
                    <label>Full Name<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.fullname}}</span>  </v-row > 
                    <!-- <v-text-field placeholder="Enter Full Name"  label="Full Name"  v-model="student_Data.fullname"></v-text-field> -->
                </v-col>
                <v-col cols="12" sm="3" v-else>
                      <label>Full Name<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.fullname}}</span>  </v-row > 
                    <!-- <v-text-field readonly placeholder="Enter Full Name"  label="Full Name"  v-model="student_Data.fullname"></v-text-field> -->
                </v-col> 
                <v-col cols="12" sm="3">
                     <label>Gender<span class="required">*</span> :</label>
                   <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.gender}}</span>  </v-row > -->
                    <v-select
                        :items="g_List"
                        v-model="student_Data.gender"
                        item-text="gender"
                        item-value="gender_id"
                        required
                    ></v-select>   
                </v-col>
                <v-col cols="12" sm="3" >
                    <label>Date of Birth<span class="required">*</span> :</label>
                    <!-- <v-row no-gutters> <span class="lbl" > {{student_Data.bdate}}</span>  </v-row > -->
                <datepicker  class="example"  placeholder="Select Date" v-model="student_Data.bdate"></datepicker>
                </v-col>
                <v-col cols="12" sm="3" v-if="student_Data.year == null">
                    <label>Year<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.year}}</span>  </v-row > 
                    <!-- <v-select
                        :items="y_List"
                        label="Select Year"
                        v-model="student_Data.year"
                        item-text="year"
                        item-value="id"
                        required
                    ></v-select>   -->
                </v-col>
                <v-col cols="12" sm="3" v-else>
                     <label>Year<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.year}}</span>  </v-row >
                    <!-- <v-select
                        readonly
                        :items="y_List"
                        label="Select Year"
                        v-model="student_Data.year"
                        item-text="year"
                        item-value="id"
                        required
                    ></v-select>      -->
                </v-col> 
                <v-col cols="12" sm="3" v-if="student_Data.department == null">
                     <label>Department<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.department}}</span>  </v-row >
                    <!-- <v-select
                        :items="d_List"
                        label="Select Department"
                        v-model="student_Data.department"
                        item-text="department"
                        item-value="id"
                        required
                    ></v-select>      -->
                </v-col>
                <v-col cols="12" sm="3" v-else>
                      <label>Department<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.department}}</span>  </v-row >
                    <!-- <v-select
                        readonly
                        :items="d_List"
                        label="Select Department"
                        v-model="student_Data.department"
                        item-text="department"
                        item-value="id"
                        required
                    ></v-select>      -->
                </v-col>
                <v-col cols="12" sm="3" v-if="student_Data.program == null">
                      <label>Program<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.program}}</span>  </v-row >
                    <!-- <v-select
                        :items="p_List"
                        label="Select Program"
                        v-model="student_Data.program"
                        item-text="program"
                        item-value="id"
                        required
                    ></v-select>      -->
                </v-col>
                <v-col cols="12" sm="3" v-else>
                    <label>Program<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.program}}</span>  </v-row >
                    <!-- <v-select
                        readonly
                        :items="p_List"
                        label="Select Program"
                        v-model="student_Data.program"
                        item-text="program"
                        item-value="id"
                        required
                    ></v-select>      -->
                    </v-col>
                    <v-col cols="12" sm="3">
                         <label>Year of Passing<span class="required">*</span> :</label>
                    <v-select
                        :items="aylist"
                        v-model="student_Data.yearofpassing"
                        item-text="ay"
                        item-value="ay_id"
                        :rules="[v => !!v || 'required']" 
                    ></v-select>     
                </v-col>
                    <v-col cols="12" sm="3">
                         <label>Select Blood Group<span class="required">*</span> :</label>
                    <v-select
                        :items="b_List"
                        v-model="student_Data.bgroup"
                        item-text="b_group"
                        item-value="id"
                        :rules="[v => !!v || 'required']" 
                    ></v-select>     
                </v-col>
                <v-col cols="12" sm="3">
                         <label>Select Nationality<span class="required">*</span> :</label>
                    <v-select
                        :items="nationality_List"
                        v-model="student_Data.nationality"
                        item-text="name"
                        item-value="id"
                        :rules="[v => !!v || 'required']" 
                    ></v-select>     
                </v-col>
               
                <v-col cols="12" sm="3">
                    <label>Personal Email<span class="required">*</span> :</label>
                    <v-text-field type="email" :error-messages="error.student_Data.pemail"  placeholder="Enter Personal Email" :rules="emailRules" oninput="this.value = this.value.replace(/[^a-z0-9@.]/, '')" v-model="student_Data.pemail"><span class="required">*</span></v-text-field>
                </v-col> 
                <v-col cols="12" sm="3">
                     <label>Institute Email<span class="required">*</span> :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.iemail}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                   <label>Contact Number<span class="required">*</span> :</label>
                   <v-text-field type="number" :value="0" min="0" hint="Enter Number Only" v-model="student_Data.contact" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                   <label>Alternate Contact Number<span class="required">*</span> :</label>
                   <v-text-field type="number" :value="0" min="0" hint="Enter 10 digit Number Only" v-model="student_Data.alternate_cont"></v-text-field>
                </v-col>
                 
                 <v-col cols="12" sm="3">
                     <label>Language<span class="required">*</span> :</label>
                    <v-select
                        :items="l_List"
        
                        v-model="getlang"
                        multiple
                        item-text="language"
                        item-value="id"
                        :rules="[v => !!v || 'required']" 
                    ></v-select>   
                               
                </v-col>                


                 <!-- <v-col cols="12" sm="3">
                 <v-select  v-model="student_Data.year_passing" :items="yearofpassing_list" 
                            item-text="ay"
                            item-value="ay_id"
                             label="Year Of Passing*" dense ></v-select>
                     
                </v-col> -->

                     <!-- <label>Is Year Down after 10th<span class="required"></span> :</label> -->
                      <!-- <v-checkbox v-model="student_Data.isyeardown" color="success" label="Is Year Gap after 10th?"></v-checkbox> -->

                      <!-- <span v-if="student_Data.isplaced == false">
                      <v-checkbox style="margin-top:-23px;" v-model="student_Data.isplaced" color="success" label="Are you placed?" @click="placed()"></v-checkbox>
                      </span><span v-else><label>Are you placed? : YES</label></span> -->
                      <!-- <span v-if="student_Data.isintern == false"
                      <v-checkbox v-model="student_Data.isintern" color="success" label="Have you received any Internship?"></v-checkbox>
                        </span><span v-else>YOU HAVE RECEIVED AN INTERNSHIP</span> -->
               
                    
                <v-col cols="12" sm="3">
                     <label>Technical Interest<span class="required"></span> :</label>
                    <v-text-field placeholder="Enter Technical Interest"  v-model="student_Data.technical" density="compact" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.student_Data.technical ? 'Please enter valid technical skills' : ''"></v-text-field>
                </v-col>
               
                <v-col cols="12" sm="3">
                     <label>Strength<span class="required"></span> :</label>
                    <v-text-field placeholder="Enter Strength" v-model="student_Data.strenght" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32"
                      :error-messages="error.student_Data.strenght ? 'Please Enter Valid Strength' : ''"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                     <label>Weakness<span class="required"></span> :</label>
                    <v-text-field placeholder="Enter Weakness"  v-model="student_Data.weakness" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32"
                      :error-messages="error.student_Data.weakness ? 'Please Enter weakness' : ''"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                     <label>Hobbies<span class="required"></span> :</label>
                   <v-text-field placeholder="Enter Hobbies" v-model="student_Data.hobbies" :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32"
                      :error-messages="error.student_Data.hobbies ? 'Please Enter Valid Qualification' : ''"></v-text-field>
                </v-col>
                 <!-- <v-col cols="12" sm="3">
                      <label>Describe Yourself<span class="required"></span> :</label>
                    <v-text-field placeholder="Enter Describe Yourself"  v-model="student_Data.yourself"></v-text-field>
                </v-col> -->
              
                <!-- <v-col cols="12" sm="3">
                     <label>Career Objective<span class="required"></span> :</label>
                   <v-text-field placeholder="Enter Career Objective" v-model="student_Data.objective"></v-text-field>
                </v-col> -->
                    <v-col cols="12" sm="3">
                     <label>Aadhar Number<span class="required">*</span> :</label>
                   <v-text-field :rules="applyRules"  placeholder="Enter Adhar Number" v-model="student_Data.adhar" oninput="this.value = this.value.replace(/[^0-9]/, '')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                     <label>Passport Number<span class="required"></span> :</label>
                   <v-text-field  placeholder="Enter Passport Number" v-model="student_Data.passport" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/, '')" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                     <label>PAN Number<span class="required"></span> :</label>
                   <v-text-field :rules="applyRules" placeholder="Enter Pan card Number" v-model="student_Data.pan" oninput="this.value = this.value.replace(/[^A-Za-z0-9,-_]/, '')"></v-text-field>
                </v-col>

                <v-col cols="12" sm="9"></v-col>
               </v-row>
               <v-row>

             <!-- <v-col cols="12" sm="8">
                 <v-card>
                 <v-row>
                        <v-col cols="12" sm="8" >
                            <v-select  style="margin-left:20px" v-model="student_Data.selected_highestqua" :items="high_qua_list"  
                            item-text="degree_name"
                            item-value="degree_id"
                            label="Highest Qualification*" dense ></v-select>
                         </v-col>
                        <v-col cols="12" sm="2" >
                            <router-link to="/student-academics" target="_blank"><v-btn color="primary" class="logi" dark><v-icon>mdi-plus</v-icon></v-btn></router-link>
                        </v-col>
                            </v-row>
                        <v-col  cols="12" sm="8">
                            <v-select style="margin-left:10px" v-model="student_Data.year_passing" :items="yearofpassing_list" 
                            item-text="ay"
                            item-value="ay_id"
                             label="Year Of Passing*" dense ></v-select>
                            </v-col>
                            <v-row>
                        <v-col  cols="12" sm="8">
                    
                            <v-select style="margin-left:20px" v-model="student_Data.selected_cgpa" :items="current_inst_aca_list"
                            item-text="cgpa_name"
                            item-value="cgpa_id"
                              label="Graduation Latest CGPA*" dense ></v-select>
                         </v-col>
                          <v-col cols="12" sm="2" >
                             <router-link to="/student-Current-academics" target="_blank"><v-btn color="primary" class="logi" dark><v-icon>mdi-plus</v-icon></v-btn></router-link>
                            </v-col>
                         </v-row>
                   </v-card>
              </v-col>-->
               
                </v-row>
           </v-card-text>
       </v-card>

<!-----------is freezed card -->
              <v-card v-else>
           <v-card-text>
               <v-row >
                <v-col cols="12" sm="3">  
                    <label>Registration Number :</label>
                 <v-row no-gutters> <span class="lbl"> {{student_Data.grno}}</span>  </v-row >                  
                 </v-col>
                <v-col cols="12" sm="3" v-if="student_Data.fname == null">
                    <label> First Name<span class="required">*</span> :</label>
                   <v-row no-gutters> <span class="lbl"> {{student_Data.fname}}</span>  </v-row >   
                </v-col>
                <v-col cols="12" sm="3" v-else>
                     <label> First Name :</label>
                   <v-row no-gutters> <span class="lbl" > {{student_Data.fname}}</span>  </v-row >  
                </v-col>
                <v-col cols="12" sm="3">
                 <label>Middle Name :</label>
                   <span class="lbl" >{{student_Data.mname}}</span>
                 </v-col>  
                <v-col cols="12" sm="3">
                    <label>Last Name :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.lname}}</span>  </v-row > 
                </v-col>
                <v-col cols="12" sm="3">
                      <label>Full Name :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.fullname}}</span>  </v-row > 
                </v-col> 
                <v-col cols="12" sm="3">
                     <label>Gender :</label>
                   <v-row no-gutters> <span class="lbl" > {{student_Data.gender}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3" >
                    <label>Date of Birth :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.bdate}}</span>  </v-row >
                </v-col>
               <v-col cols="12" sm="3" >
                    <label>Year :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.year}}</span>  </v-row >
                </v-col> 
                <v-col cols="12" sm="3" >
                    <label>Department :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.department}}</span>  </v-row >
                </v-col>
               <v-col cols="12" sm="3" >
                    <label>Program :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.program}}</span>  </v-row >
                </v-col>
                 <v-col cols="12" sm="3">
                         <label>Year of Passing<span class="required">*</span> :</label>
                         <v-row no-gutters> <span class="lbl" > {{student_Data.yearofpassingay}}</span>  </v-row >
                </v-col>
                    <v-col cols="12" sm="3">
                         <label>Select Blood Group<span class="required">*</span> :</label>
                         <v-row no-gutters> <span class="lbl" > {{student_Data.bgroupname}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                         <label>Select Nationality<span class="required">*</span> :</label>
                         <v-row no-gutters> <span class="lbl" > {{student_Data.nationality_name}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                    <label>Personal Email :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.pemail}}</span>  </v-row >
                </v-col> 
                <v-col cols="12" sm="3">
                     <label>Institute Email :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.iemail}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                   <label>Contact Number :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.contact}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                   <label>Alternate Contact Number :</label>
                   <v-row no-gutters> <span class="lbl" > {{student_Data.alternate_cont}}</span>  </v-row >
                </v-col>
                 <v-col cols="12" sm="3">
                     <label>Language :</label>
                     <v-row no-gutters> <span v-for="(item, index) in student_Data.language" :key="index" class="lbl" > <span v-if="index != 0">,</span> {{item.name}}  </span>  </v-row >
                </v-col>

                     <!-- <label>Is Year Gap after 10th <span class="required"></span> :</label>
                     <span v-if="student_Data.isyeardown == true">: Yes</span><span v-else >: NO</span> -->
                                          <!-- </v-col> -->
                    <!-- <label>Are you placed? <span class="required"></span> :</label>
                     <span v-if="student_Data.isplaced == true">: Yes</span><span v-else >: NO</span> -->
                
                       <!-- <label>Have you received any internship? <span class="required"></span> :</label>
                     <span v-if="student_Data.isintern == true">: Yes</span><span v-else >: NO</span>
                      </v-col> -->

                <v-col cols="12" sm="3">
                     <label>Technical Interest :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.technical}}</span>  </v-row >
                </v-col>
               
                <v-col cols="12" sm="3">
                     <label>Strength :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.strenght}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                     <label>Weakness :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.weakness}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                     <label>Hobbies :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.hobbies}}</span>  </v-row >
                </v-col>
                 <!-- <v-col cols="12" sm="3">
                      <label>Describe Yourself  :</label>
                      <v-row no-gutters> <span class="lbl" > {{student_Data.yourself}}</span>  </v-row >
                </v-col> -->
              
                <!-- <v-col cols="12" sm="3">
                     <label>Career Objective :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.objective}}</span>  </v-row >
                </v-col> -->
                    <v-col cols="12" sm="3">
                     <label>Adhar Number :</label>
                      <v-row no-gutters> <span class="lbl" > {{student_Data.adhar}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                    <label>Passport Number :</label>
                    <v-row no-gutters> <span class="lbl" > {{student_Data.passport}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="3">
                     <label>PAN Number :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.pan}}</span>  </v-row >
                </v-col>
                <v-col cols="12" sm="6"></v-col>

               </v-row>
             
           </v-card-text>
       </v-card>

       <v-row v-if="student_Data.ispersonaldetailsfreezed == false">
        <v-col cols="12" sm="12">
                   <v-card>
                       <v-card-title class="tc-title">Local Address<span class="mr-1" style="color: red;"></span>: </v-card-title>
                        <v-row>
                            <v-col col-sm="6">
                            <v-textarea label="Address*"  v-model="local.currentaddress" style="margin: 8px;" class="pt-0 pb-0"
                            :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 ||  event.charCode == 32"
                      :error-messages="error.local.currentaddress ? 'Please Enter Valid Addresss' : ''"></v-textarea>
                            </v-col>
                            <v-col col-sm="2">
                            <v-text-field label="Pin*"  v-model="local.currentpin" :rules="applyRules" oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;"  ></v-text-field>
                            <v-select v-model="local.currentcountry" :items="country_list" item-text="state" label="Country*" dense ></v-select>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12">
                        <h4><input type="checkbox"
                      v-model="checkbox"
                      color="success" style="margin: 0.4rem; width:18px; height: 15px; margin-left: 30%;" @change="copyadd(checkbox)"
                      />&nbsp;&nbsp;Whether Permanent Address is same as Local Address</h4>
                   </v-col>
                <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="tc-title">Permanent Address<span class="mr-1" style="color: red;"></span>: </v-card-title>
                        <v-row>
                            <v-col col-sm="6">
                            <v-textarea label="Address*" v-model="permanent.permanentaddress" style="margin: 8px;" class="pt-0 pb-0"
                            :rules="[ruless, rules.isEmpty]"
                      onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 || event.charCode == 32"
                      :error-messages="error.permanent.permanentaddress ? 'Please Enter Valid Addresss' : ''"></v-textarea>
                            </v-col>
                            <v-col col-sm="2">
                            <v-text-field label="Pin*" v-model="permanent.permanentpin" :rules="applyRules" oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;"  ></v-text-field>
                            <v-select v-model="permanent.permanentcountry" :items="country_list"  label="Country*" dense ></v-select>
                            </v-col>
                        </v-row>
                </v-card>
                </v-col>
       </v-row>
       <v-row v-else>
        <v-col cols="12" sm="12" class="mt-3">
                   <v-card>
                    <v-card-title class="tc-title">Local Address<span style="color: red;"></span>: </v-card-title>
                        <v-row>
                            <v-col col-sm="6">
                            <v-row no-gutters> <span class="lbl ml-2" >  <b style="color: black;">Address :</b> {{local.currentaddress}}</span>  </v-row >
                            <v-row no-gutters> <span class="lbl ml-2" >  <b style="color: black;">Pin :</b> {{local.currentpin}}</span>  </v-row >
                            <v-row no-gutters> <span class="lbl ml-2" >  <b style="color: black;">Country :</b> {{local.currentcountry}}</span>  </v-row >
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
               
                <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="tc-title">Permanent Address<span class="mr-1" style="color: red;"></span>: </v-card-title>
                        <v-row>
                            
                            <v-col col-sm="2">
                             <v-row no-gutters><span class="lbl ml-2" >   <b style="color: black;">Address :</b> {{permanent.permanentaddress}}</span> </v-row>
                             <v-row no-gutters><span class="lbl ml-2" >    <b style="color: black;">Pin :</b> {{permanent.permanentpin}}</span> </v-row>
                             <v-row no-gutters><span class="lbl ml-2" >    <b style="color: black;">Country :</b> {{permanent.permanentcountry}}</span> </v-row>
                            </v-col> 

                        </v-row>
                </v-card>
                </v-col>
       </v-row>

       <div v-if="student_Data.ispersonaldetailsfreezed == false" align="center">
       <v-col cols="12" sm="3" align="center"></v-col> 
                  <v-col v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="5" align="center">
                      <v-btn v-if="saved" style="margin-top:20px" color="warning darken-1" dark @click="freezepersonaldetails()">Freeze</v-btn>   &nbsp; 
                      <v-btn style="margin-top:20px" color="primary darken-1"  dark @click="save()">Save</v-btn><br>
                      <span  v-if="saved" style="font-size:16px;font-weight:bold;color:blue;"><br>NOTE : Once you freeze the details,it can not be changed.</span><br>
                   </v-col>
                   <v-col v-else cols="12" sm="2" align="center"><br>
                      <span style="font-size:16px;font-weight:bold;color:red;">You Have Already Freezed the Details.</span>
                   </v-col>
       </div>
       <div v-else>
                   <v-row>
                   <v-col cols="12" sm="5" align="center"></v-col> 
                   <v-col v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="2" align="center">
                      <span style="font-size:16px;font-weight:bold;color:blue;">NOTE : Once you freeze the details, it can not be changed.</span>
                      <v-btn style="margin-top:20px" color="primary darken-1"  dark @click="save()">Save & Freeze</v-btn>
                   </v-col>
                   <v-col v-else cols="12" sm="2" align="center"><br>
                      <span style="font-size:16px;font-weight:bold;color:red;">You Have Freezed the Details.</span>
                      <v-btn style="margin-top:20px" color="primary darken-1"  dark @click="unfreezeStudentdetails('personal')">Unfreeze</v-btn>
                   </v-col>
                </v-row>
            </div>

</div>
</template>
<script>
import StudentProfile from './StudentProfile'
import Nevigation from './Nevigation'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
 import datetime from 'vuejs-datetimepicker'
import axios from 'axios'
    export default {
        components:{
            StudentProfile,
            Datepicker,
            datetime,
            Nevigation
        },
        data () {
            return {
                ruless: [
        (value) => {
          if (!value) {
            return [];
          } else if (/^\d+$/.test(value)) {
            return "Numeric values are not allowed";
          } else {
            return true;
          }
        },
      ],
      rules: {
        isEmpty: (value) => !!value || 'Field is required',
      },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: '',
            countryList:'',
            saved : false,
            
            vModelExample: null,
          
            savedata:[],
            g_List:'',
            y_List:'',
            d_List:'',
            p_List:'',
            b_List:'',
            checkbox: false,
            nationality_List:'',
            aylist:[],
            l_List:[],   
            language:[],
            getlang:[],
            yearofpassing_list:[],
            country_list:[],
            emailRules: [
  v => !!v || 'E-mail is required',
  v => /^(([^<>()[\]\\.,;:\s\@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid', 
],         
            nameRules:[
            v => !!v || 'Enter valid name',
            v =>  /^[a-zA-Z]+$/.test(v) || 'name must be valid'
            ],  

            applyRules:[
            v => !!v || 'Required',
            v =>  /^[^\s]+(\s+[^\s]+)*$/.test(v) || 'required'
            ],

            // adharRules:[
            // v => !!v || 'Required',
           
            // ],

            
            
            student_Data:{
               grno:null,
               fullname:null,
               fname:'',
               mname:'',
               lname:'',
               gender:null,
               bdate:null,
               year:null,
               department:null,
               program:null,
               pemail:null,
               
               iemail:null,
               language:[],
               contact:null,
               bgroup:null,
               nationality:null,
               yearofpassing:null,
               strenght:null,
               weakness:null,
               hobbies:null,
               yourself:null,
               technical:null,
               objective:null,
                pan:"",
               adhar:"",
               passport:null,
               alternate_cont:null,
               year_passing:null,
               ispersonaldetailsfreezed:false,
               isyeardown:false,
               isintern:false,
               isplaced:false,

               
            },

            

            error: {
                student_Data:{
                    fname: false,
                    mname: false,
                    lname: false,
                    pemail: false,
                    adhar: false,
                },
                local:{
                    currentaddress: false,
                    currentpin:false,
                    currentcountry:false,
                },
                permanent:{
                    permanentaddress: false,
                    permanentpin: false,
                    permanentcountry: false,
                }
            },
            local:{
                currentcountry:null,
               currentpin:null,
               currentaddress:null,
            },
            permanent:{
                permanentaddress:null,
               permanentpin:null,
               permanentcountry:null,
    
            },
            addressdata:[],
            modal: false,
            menu2: false,
            olddate:null ,
            current_date:null,
            }
           
        },
        mounted() { 
            this.onLoad()
            this.current_date= new Date().toISOString().slice(0,10);
            
        },
        methods: {
            copyadd(item)
            {
                if(item==true){
                    this.permanent.permanentaddress=this.local.currentaddress;
                    this.permanent.permanentpin=this.local.currentpin;
                    this.permanent.permanentcountry=this.local.currentcountry;
                }else if(item==false){
                    this.permanent.permanentaddress="";
                    this.permanent.permanentpin="";
                    this.permanent.permanentcountry="";  
                }
                
            },
            fullname(){
                this.student_Data.fullname=this.student_Data.fname+" "+this.student_Data.mname+" "+this.student_Data.lname
            },
            onLoad(){
                    axios
                        .post("/LearnerData/learnerPersonalDetails")
                        .then(res => {
                            if (res.data.msg == "200") {
                                    //All List
                                    this.g_List=res.data.gender_List
                                    this.y_List=res.data.year_List
                                    this.d_List=res.data.department_List
                                    this.p_List=res.data.program_List
                                    this.b_List=res.data.bloodgroup_List
                                    this.nationality_List = res.data.nationality_List
                                    this.aylist = res.data.yearofpassing_list
                                    this.l_List=res.data.laguage_List
                                    this.country_list=res.data.country_list
                                   // console.log(res.data.country_list)
                                    //Student data 
                                    //this.language=res.data.stud_laguage_List 
                                    this.getlang=res.data.stud_laguage_List                            
                                    this.student_Data.grno=res.data.gr_no
                                    this.student_Data.fname=res.data.f_name
                                    this.student_Data.mname=res.data.m_name
                                   
                                    this.student_Data.lname=res.data.l_name
                                    this.student_Data.fullname=res.data.full_name
                                   // this.student_Data.gender=res.data.gender_id
                                    this.student_Data.gender=res.data.gender
                                    this.student_Data.bdate=res.data.b_date
                                    this.student_Data.year=res.data.year
                                    this.student_Data.department=res.data.department
                                    this.student_Data.program=res.data.progarm
                                    this.student_Data.bgroup=res.data.b_group_id
                                    this.student_Data.bgroupname=res.data.b_group_name
                                    this.student_Data.nationality_name=res.data.nationality
                                    this.student_Data.nationality=res.data.nationality_id
                                    this.student_Data.yearofpassing=res.data.yearofpassing_id
                                    this.student_Data.yearofpassingay=res.data.yearofpassing_name
                                    this.student_Data.pemail=res.data.p_email
                                    this.student_Data.iemail=res.data.i_email
                                    this.student_Data.contact=res.data.contact
                                    this.student_Data.language=res.data.language
                                    this.student_Data.strenght=res.data.strength
                                    this.student_Data.weakness=res.data.weakness
                                    this.student_Data.hobbies=res.data.hobiess
                                    this.student_Data.yourself=res.data.yourself
                                    this.student_Data.technical=res.data.technical
                                    this.student_Data.objective=res.data.objective
                                    this.olddate=res.data.b_date
                                    this.student_Data.adhar=res.data.adhar
                                    this.student_Data.passport=res.data.passport
                                    this.student_Data.pan = res.data.pan
                                    this.student_Data.alternate_cont = res.data.alternate_cont
                                    this.student_Data.year_passing = res.data.ay_id
                                    //console.log("this.student_Data.year_passing");
                                    //console.log(this.student_Data.year_passing);
                                    this.local.currentaddress = res.data.currentaddress
                                    this.local.currentpin = res.data.currentpin
                                    this.local.currentcountry = res.data.currentcountry
                                    this.permanent.permanentaddress = res.data.permanentaddress
                                    this.permanent.permanentpin = res.data.permanentpin
                                    this.permanent.permanentcountry = res.data.permanentcountry
                                    this.yearofpassing_list= res.data.yearofpassing_list
                                    this.student_Data.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed
                                    this.student_Data.isyeardown = res.data.isyeardown
                                    this.student_Data.isplaced = res.data.isplaced
                                    this.student_Data.isintern = res.data.isintern
                                
                                    //this.language = res.data.language

                            } else {
                                this.showSnackbar("#b71c1c", "No Data");
                               // window.console.log(res.data.msg)
                            }
                        })
                        .catch(error => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            //window.console.log(error);
                        })
                        .finally(() => {
                            // 'use strict';
                        });
                },
                showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                freezepersonaldetails(){
                    if(confirm("Are you sure, Want to Freeze Details, Once Freezed cannot be edited?")){
                        axios
                            .post("/LearnerData/freezepersonaldetails")
                            .then((res) => {
                                if (res.data.msg == "200") {
                                    this.dialog_edit = false;
                                    this.showSnackbar("#4caf50", "Details Freezed Successfully."); 
                                    this.student_Data.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed;
                                
                                } else {
                                    this.showSnackbar("#b71c1c", "Error While Freeze Data");
                                }
                            })
                            .catch((error) => {
                               // window.console.log(error);
                            });
                    }
                },
                          
            close() {
                this.dialog = false
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },

             unfreezeStudentdetails(item){
                  if(confirm("Are you sure, you want to unfreeze ?")){
            const data = {
                    value:item,                  
                };
       axios
            .post("/LearnerData/unfreezeStudentdetailsstudentside",data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.onLoad();
                this.showSnackbar("#4caf50", "Details Unfreezed Successfully."); // show snackbar on success

              
               
              } else {
                // this.editAllData.splice(this.editAllData);
                // this.showSnackbar("#b71c1c", "Record Already Present!!!");
              }
            })
            .catch((error) => {
              //window.console.log(error);
            });
                  }
    },
            placed(){
                if(confirm("Are you sure, you want to mark this field?")){
                    this.student_Data.isplaced = true;
                }
            },
            hasWhiteSpace(s) {
                var status = false;
                var reWhiteSpace = new RegExp(/^\s+$/);

                // Check for white space
                if (reWhiteSpace.test(s)) {
                    // alert("Please Check Your Fields For Spaces");
                    //   return true;
                    return /^\s/.test(s);
                }
                //   return false;
                return /^\s/.test(s);
            },

            containsSpaces(value) {
                return /\s/.test(value);
            },
            handleInput(event) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, '');
  },
            save() {
                    this.student_Data.language=this.getlang;    
                    var checkbirthdate = new Date().setYear( new Date().getYear() +1900 - 17)
                    var birthdate = new Date(this.student_Data.bdate);
                    
                    
                    if(!this.student_Data.fname){
                        this.showSnackbar("#b71c1c", "Please Enter First Name ...");
                        return 
                    }
                    else if(this.student_Data.mname== ""){
                        this.showSnackbar("#b71c1c", "Please Enter Middle Name ...");
                        return
                    }
                    else if(!this.student_Data.lname){
                        this.showSnackbar("#b71c1c", "Please Enter Last Name ...");
                        return
                    }
                    else if(this.student_Data.gender==null || this.student_Data.gender== ""){
                        this.showSnackbar("#b71c1c", "Please Select Gender...");
                        return;
                    }else if(this.student_Data.bdate == null || this.student_Data.bdate == ""){
                          this.showSnackbar("#b71c1c", "Select Date of Birth...");
                          return;
                    }else if(this.student_Data.bdate && birthdate > checkbirthdate){
                          this.showSnackbar("#b71c1c", "Minimum age Should be 17 years, Please Select Birth Date Accordingly");
                          return;
                    }else if(this.student_Data.pemail== null || this.student_Data.pemail== ""){
                        this.showSnackbar("#b71c1c", "Enter Personal Email Id...");
                        return;
                    }
                    

                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!this.student_Data.pemail || this.student_Data.pemail.trim() === '' || !emailRegex.test(this.student_Data.pemail.trim())) {
                        this.showSnackbar("#b71c1c", "Enter valid Personal Email Id...");
                        return;
                    }else if(this.student_Data.yearofpassing=="" || this.student_Data.yearofpassing== null){
                        this.showSnackbar("#b71c1c", "Select Current Degree Year of passing ...");
                        return;
                    }else if(this.student_Data.bgroup==null || this.student_Data.bgroup== ""){
                        this.showSnackbar("#b71c1c", "Select Blood Group...");
                        return;
                    }else if(this.student_Data.nationality==null || this.student_Data.nationality== ""){
                        this.showSnackbar("#b71c1c", "Select Nationality...");
                        return;
                    }else if(this.student_Data.contact == null || this.student_Data.contact == ""){
                        this.showSnackbar("#b71c1c", "Enter Contact Number ");
                        return;
                    }else if (this.student_Data.contact < 0){
                        this.showSnackbar("#b71c1c", "Enter Valid Contact Number ");
                        return;
                    }else if(this.student_Data.contact && (this.student_Data.contact.length < 10 || this.student_Data.contact.length >10)){
                        this.showSnackbar("#b71c1c", "Enter 10 digit Contact Number ");
                        return;
                    }
                    else if(this.student_Data.alternate_cont == null || this.student_Data.alternate_cont == ""){
                        this.showSnackbar("#b71c1c", "Enter Alternate Contact Number ");
                        return;
                    }else if(this.student_Data.alternate_cont && (this.student_Data.alternate_cont.length < 10 || this.student_Data.alternate_cont.length >10)){
                        this.showSnackbar("#b71c1c", "Enter 10 digit Alternate Contact Number ");
                        return;
                    }else if(this.student_Data.language == null || this.student_Data.language== ""){
                        this.showSnackbar("#b71c1c", "Select Language");
                        return;
                    }else if(/^[ \d]+$/.test(this.student_Data.technical) || this.hasWhiteSpace(this.student_Data.technical)){
                        this.showSnackbar("#b71c1c", "Enter valid technical skills...");
                        return
                    }
                    else if(/^[ \d]+$/.test(this.student_Data.strenght) || this.hasWhiteSpace(this.student_Data.strenght)){
                        this.showSnackbar("#b71c1c", "Enter valid strenght...");
                        return
                    }
                    else if(/^[ \d]+$/.test(this.student_Data.weakness) || this.hasWhiteSpace(this.student_Data.weakness)){
                        this.showSnackbar("#b71c1c", "Enter valid weakness...");
                        return
                    }
                    else if(/^[ \d]+$/.test(this.student_Data.hobbies) || this.hasWhiteSpace(this.student_Data.hobbies)){
                        this.showSnackbar("#b71c1c", "Enter valid hobbies...");
                        return
                    }
                    else if(this.local.currentaddress ==null || this.local.currentaddress == "" || /^[ \d]+$/.test(this.local.currentaddress) || this.hasWhiteSpace(this.local.currentaddress)){
                        this.showSnackbar("#b71c1c", "Enter Current Address ");
                        return;
                    }else if(this.local.currentpin == null || this.local.currentpin == ""){
                        this.showSnackbar("#b71c1c", "Enter Current Pin ");
                        return;
                    }else if(this.local.currentcountry == null || this.local.currentcountry == ""){
                        this.showSnackbar("#b71c1c", "Select Current Country ");
                        return;
                    }else if(this.permanent.permanentcountry == null || this.permanent.permanentcountry == ""){
                        this.showSnackbar("#b71c1c", "Select Permanent Country ");
                        return;
                    }else if(this.permanent.permanentpin ==null || this.permanent.permanentpin == ""){
                        this.showSnackbar("#b71c1c", "Enter Permanent Pin ");
                        return;
                    }else if(this.permanent.permanentaddress ==null || this.permanent.permanentaddress == "" || /^[ \d]+$/.test(this.permanent.permanentaddress) || this.hasWhiteSpace(this.permanent.permanentaddress)){
                        this.showSnackbar("#b71c1c", "Enter Permanent Address ");
                        return;
                    }
                    else {
                         if(this.student_Data.adhar != null){
                            
                            if( this.student_Data.adhar.length < 12 || this.student_Data.adhar.length > 12 || !(/^[^\s]+(\s+[^\s]+)*$/).test(this.student_Data.adhar)){
                                this.showSnackbar("#b71c1c", "Enter 12 Digit valid Adhar Number ");
                                return;
                            }
                         }
                         
                          if(this.student_Data.pan !=null){
                            
                            if( this.student_Data.pan.length < 10 || this.student_Data.pan.length > 10){
                                this.showSnackbar("#b71c1c", "Enter 10-digit alphanumeric PAN Number ");
                                return;
                            }
                         } 
                       
                       if (this.student_Data.iemail != "" && this.student_Data.pemail != ""){
                    
                                if(this.student_Data.iemail == this.student_Data.pemail){
                                    this.showSnackbar("#b71c1c", "Personal Email & institute email can not be same");
                                    return
                                }
                            }
                           
                        const data={
                            studentdata:this.student_Data,
                            local:this.local,
                            permanent:this.permanent,
                        };
                        axios
                            .post("/LearnerData/savePersonalDetails",data)
                            .then(res => {
                                if (res.data.msg == "200") {
                                    this.onLoad();
                                    this.saved = true
                                    this.showSnackbar("#4caf50", "Personal Details Save Successfully..."); // show snackbar on success
                                }else{
                                    this.showSnackbar("#b71c1c", "Something went wrong...");
                                }
                            })
                            .catch(error => {
                                //window.console.log(error);
                            });
                        this.close();
                    }
                
            },
            filterAlphabets(evt) {
                evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!(/^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$/).test(expect) ) {
        evt.preventDefault();
      } else {
        return true;
      }

    },
    // filterNumber(evt) {
    //             evt = (evt) ? evt : window.event;
    //   let expect = evt.target.value.toString() + evt.key.toString();
      
    //   if (!(/^(?!\s)\d+(?:\s\d+)*$/).test(expect) ) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }

    // },


    



        },
    }
</script>
<style scoped>
    .nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.example {
  margin-top: 5px;
  background: #ffff;
  border-bottom: 1px solid black;
  color: black;
}

.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
  .required{
  color:red;
  font-size:14px;
  font-style:bold;
  }
  .blink {
            animation: blinker 0.77s linear infinite;
            color: red;
            font-family: sans-serif;
        }
        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
        .tc-title1 {
  background-image: linear-gradient(-90deg, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>